import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/Buttons.js";
import TeamIllustrationSrc from "images/empower-taxes-01.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center text-primary-800 md:text-left`;
const Heading = tw(
    SectionHeading
)`mt-4 font-black text-gray-700 text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Description1 = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-700`;
const Description2 = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-700`;
const Description3 = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-700`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
    tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
    props.buttonRounded && tw`rounded-full`
]);

export default ({
                    heading = <>990 Filing</>,
                    subheading = "Starts at $150",
                    description1 = <><span tw="font-extrabold text-primary-800">990-N </span>&mdash; For organizations under $50k in annual Gross Receipts. Includes eFile for the 990-N form, and a light analysis of your organization’s accounting system and compliance. &mdash; <span tw="font-thin">$150 est.</span></>,
                    
                    description2 = <><span tw="font-extrabold text-primary-800">990-EZ </span>&mdash; For organizations between $50 - $200k in annual Gross Receipts, and under $500k in Assets. Includes eFile for the 990-EZ form, and a light analysis of your organization’s accounting system and compliance. &mdash; <span tw="font-thin">$750 est.</span></>,
                    
                    description3 = <><span tw="font-extrabold text-primary-800">Full Form 990</span>&mdash; For organizations above $200k in annual Gross Receipts or $500k in Assets. Includes eFile for the Form 990, and a light analysis of your organization’s accounting system and compliance. &mdash; <span tw="font-thin">$750 - $1500 est.</span></>,
                    
                    primaryButtonText = "Schedule a Call",
                    primaryButtonUrl = "https://calendly.com/empowert3",
                    buttonRounded = true,
                    features = null,
                    textOnLeft = false
                }) => {
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
    
    /*
     * Change the features variable as you like, add or delete objects
     * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
     */
    // const defaultFeatures = [
    //     {
    //         Icon: BriefcaseIcon,
    //         title: "990-N",
    //         description: "For organizations under $50k in annual Gross Receipts. Includes eFile for the 990-N form, and a light analysis of your organization’s accounting system and compliance."
    //     },
    //     {
    //         Icon: MoneyIcon,
    //         title: "990-EZ",
    //         description: "For organizations between $50 - $200k in annual Gross Receipts, and under $500k in Assets. Includes eFile for the 990-EZ form, and a light analysis of your organization’s accounting system and compliance."
    //     },
    //     {
    //         Icon: BriefcaseIcon,
    //         title: "Full Form 990",
    //         description: "For organizations above $200k in annual Gross Receipts or $500k in Assets. Includes eFile for the Form 990, and a light analysis of your organization’s accounting system and compliance."
    //     }
    // ];
    
    // if (!features) features = defaultFeatures;
    
    return (
        <Container>
            <TwoColumn>
                <ImageColumn>
                    <Image imageSrc={TeamIllustrationSrc} />
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Heading>{heading}</Heading>
                        <Subheading>{subheading}</Subheading>
                        <Description1>{description1}</Description1>
                        <Description2>{description2}</Description2>
                        <Description3>{description3}</Description3>
                        {/*<Features>*/}
                        {/*    {features.map((feature, index) => (*/}
                        {/*        <Feature key={index}>*/}
                        {/*            <FeatureIconContainer>{<feature.Icon />}</FeatureIconContainer>*/}
                        {/*            <FeatureText>*/}
                        {/*                <FeatureHeading>{feature.title}</FeatureHeading>*/}
                        {/*                <FeatureDescription>{feature.description}</FeatureDescription>*/}
                        {/*            </FeatureText>*/}
                        {/*        </Feature>*/}
                        {/*    ))}*/}
                        {/*</Features>*/}
                        <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
                            {primaryButtonText}
                        </PrimaryButton>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};
