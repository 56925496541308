import React from "react";
import tw from "twin.macro";
// import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/logo-light.svg";
// import { ReactComponent as FacebookIcon } from "images/demo-more/facebook-icon.svg";
// import { ReactComponent as TwitterIcon } from "images/demo-more/twitter-icon.svg";
// import { ReactComponent as YoutubeIcon } from "images/demo-more/youtube-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/demo-more/svg-decorator-blob-9.svg";

const Container = tw.div`relative bg-primary-800 text-gray-100 -mb-8 -mx-8 px-8 py-5 lg:py-16`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.div`ml-2 text-xl font-black tracking-wider text-gray-100`;

const LinksContainer = tw.div`font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const CopyrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-200`;

// const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
// const SocialLink = styled.a`
//   ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
//   svg {
//     ${tw`w-4 h-4`}
//   }
// `;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-primary-600 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-primary-600 opacity-50`;

export default () => {
  return (
    <Container style={{marginRight:"0", marginLeft:"0", maxWidth:"100%"}}>
      <Content>
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} alt="EmpowerT3 logo" />
            <LogoText>EmpowerT3</LogoText>
          </LogoContainer>
            <LinksContainer>
                <Link href="/">Home</Link>
                <Link href="/services">Services</Link>
                <Link href="/training">Training</Link>
                {/*<Link href="/about">About</Link>*/}
                <Link href="/contact">Contact Us</Link>
            </LinksContainer>
          <CopyrightNotice>&copy; 2023 EmpowerT3.</CopyrightNotice>
          {/*<SocialLinksContainer>*/}
          {/*  <SocialLink href="https://facebook.com">*/}
          {/*    <FacebookIcon />*/}
          {/*  </SocialLink>*/}
          {/*  <SocialLink href="https://twitter.com">*/}
          {/*    <TwitterIcon />*/}
          {/*  </SocialLink>*/}
          {/*  <SocialLink href="https://youtube.com">*/}
          {/*    <YoutubeIcon />*/}
          {/*  </SocialLink>*/}
          {/*</SocialLinksContainer>*/}
        </ThreeColRow>
      </Content>
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
};
