import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import tw from "twin.macro";
// import styled from "styled-components"; //eslint-disable-line
// import { css } from "styled-components/macro"; //eslint-disable-line
// import Footer from "components/main-footer.js";
import MainFeature from "pages/Services/MainFeature.js";
import Filing990 from "pages/Services/Filing990.js"
import BudgetingAssistance from "images/empower-money-02-L-640-min.jpg"
import OutsourcedBookkeeping from "images/empower-dude-04-640-min.jpg"
import ChangePlanning from "images/empower-charts-07-640-min.png"
import AccountingSystem from "images/empower-charts-02-L-640-min.png"
import OutsourcedOfficer from "images/empower-dude-01-640-min.jpg"
import CustomReports from "images/empower-charts-05-L-640-min.jpg"
// import Image990Filing from "images/empower-taxes-01.svg"
import ServicesListed from "./Services/ChartOfAccounts";
import AdditionalServices from "pages/Services/AdditionalServices";
import FAQ from "components/MainFAQ";

// const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
        return (
            <AnimationRevealPage>
                <ServicesListed />
                <MainFeature
                    heading="Budgeting Assistance"
                    // subheading={<Subheading>$750</Subheading>}
                    description="Our team can help prepare your next year(s) budget, assist with data collection and preparation, ensure the budget gets properly entered into your account system, and provide ongoing budget review services during the year(s) planned for."
                    // buttonRounded={false}
                    imageSrc={BudgetingAssistance}
                    textOnLeft={false}
                />
                <MainFeature
                  heading="Outsourced Bookkeeping"
                  // subheading={<Subheading>$750</Subheading>}
                  description="Keeping up with the day to day bookkeeping can be a grind for most leaders. Let us handle this while you focus on growing your business, church, or nonprofit. Packages begin at $250/month and can scale to accommodate all of your needs."
                  // buttonRounded={false}
                  imageSrc={OutsourcedBookkeeping}
                />
                <MainFeature
                    heading="Growth or Change Planning"
                    // subheading={<Subheading>$500</Subheading>}
                    description="If your organization is going through a change, restructure, or is otherwise planning for future changes, our team can help strategize on how to best accomplish your goals. Includes multiple sessions to talk through the need and strategize with your team. Also includes follow up preceding, during, and post the change(s) in question."
                    // buttonRounded={false}
                    imageSrc={ChangePlanning}
                    textOnLeft={false}
                />
                <MainFeature
                    heading="Accounting System and Compliance Analysis"
                    // subheading={<Subheading>$500</Subheading>}
                    description="Sometimes you just don’t know what you don’t know. Luckily, with some review we can know what you don’t know and help you know it too. Includes a deep analysis of your organization’s accounting system, and attempts to ensure you are using it to its maximum potential for your organization. Note, this does not replace the need for an official review or audit by a CPA firm; however, this could be a great way to clean things up before they perform their services."
                    // buttonRounded={false}
                    imageSrc={AccountingSystem}
                />
                <MainFeature
                    heading="Outsourced Officer"
                    // subheading={<Subheading>Variable, Depending on Scope</Subheading>}
                    description="Our team can help bridge the gap in staffing, executive, or board level oversight positions. Most commonly this takes the form of a short-term acting CFO during a staff transition or time of need, or for a stint as an addition to your organization’s board meetings to provide financial interpretation and insight."
                    // buttonRounded={false}
                    imageSrc={OutsourcedOfficer}
                    textOnLeft={false}
                />
                <MainFeature
                    heading="Custom File or Report Creation"
                    // subheading={<Subheading>Variable, Depending on Scope</Subheading>}
                    description={<>If you have a custom presentation of data that you prefer, but your accounting system does not provide, we can assist in creating custom reports and financial statements. In addition we can also develop custom files for imports. The most common example of this is getting payroll data out of your payroll system and into your accounting system, with all applicable splits by fund and category. This can also include taking data from other tools you use and ensuring it all gets into your accounting system.</>}
                    // buttonRounded={false}
                    imageSrc={CustomReports}
                />
                <Filing990 />
                <AdditionalServices />
                {/*<FAQ />*/}
                {/*<Footer />*/}
            </AnimationRevealPage>
        );
};
