import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import "./styles/master-styles.css";

import MainLandingPage from "pages/HomePage.js";
// import AboutUs from "pages/AboutUs.js";
import Services from "pages/Services.js";
import Services990 from "pages/Services/990/990.js";
import Training from "pages/Training.js";
import Pricing from "pages/Pricing.js";
import ContactUs from "pages/ContactUs.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainNav from "./components/main-nav.js";
import Footer from "./components/main-footer";

export default function App() {

  return (
    <>
      <div style={{
          flexDirection: "column",
          display: "flex",
          justifyContent: "space-between",
          minHeight: "100vh"
      }}>
            <GlobalStyles />
          <div>
            <MainNav />
            <Router>
              <Routes>
                {/*<Route path="/about" element={<AboutUs />} />*/}
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/services" element={<Services />} />
                  <Route path="/services/990" element={<Services990 />} />
                <Route path="/training" element={<Training />} />
                <Route path="/" element={<MainLandingPage />} />
              </Routes>
            </Router>
          </div>
          <Footer />
      </div>
    </>
  );
}