import React from "react";
// import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "pages/Home/Hero";
// import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Services from "pages/Home/ServicesOffered";
import Training from "pages/Home/Training";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import Pricing from "components/pricing/ThreePlans.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";
// import GetStarted from "components/cta/GetStartedLight.js";
import FAQ from "components/MainFAQ";

export default () => {
  return (
      <AnimationRevealPage disabled>
        <Hero />
        {/*<FeatureStats/>*/}
        <Services />
        <Training />
        {/*<Testimonial/>*/}
        {/*<Pricing*/}
        {/*<Blog*/}
        {/*<GetStarted/>*/}
        {/*<FAQ />*/}
        {/*<Footer />*/}
      </AnimationRevealPage>
  );
}
