import React from "react";
import tw from "twin.macro";
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "components/light.js";
const Header = tw(HeaderBase)`max-w-none`;


export default ({
                    buttonRounded = true,
                }) => {
    const buttonRoundedCss = buttonRounded && tw`rounded-full`;
    const navLinks = [
        <NavLinks key={1}>
            <NavLink href="/">Home</NavLink>
            <NavLink href="/services">Services</NavLink>
            <NavLink href="/training">Training</NavLink>
            {/*<NavLink href="/pricing">Pricing</NavLink>*/}
            {/*<NavLink href="/about">About</NavLink>*/}
            {/*<NavLink href="/contact">Contact Us</NavLink>*/}
            {/* <NavLink href="/testimonials">Testimonials</NavLink> */}
        </NavLinks>,
        <NavLinks key={2}>
            <PrimaryLink css={buttonRoundedCss} href="/contact">
                Contact Us
            </PrimaryLink>
        </NavLinks>
    ];
    return (
        <>
            <Header links={navLinks} />
        </>
    );
};
