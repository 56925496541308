import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {SectionHeading, Subheading} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/Buttons.js";

import defaultCardImage from "../../images/demo-more/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/demo-more/svg-decorator-blob-3.svg";

import CustomTrainingIcon from "images/empower-charticon-05.svg";
import DataCleanUpIcon from "images/empower-charticon-09.svg";
import GeneralConsultingIcon from "images/empower-charticon-13.svg";
import MonthlyBookkeepingIcon from "images/empower-charticon-14.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto pb-40 pt-12`}
`;
const Heading = tw(SectionHeading)`w-full text-gray-700`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/4 px-6 flex`}
`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`text-center p-6 relative`}
    img {
      ${tw``}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-700`}
  }

  .description {
    ${tw`mt-3 font-semibold text-gray-700 text-sm leading-loose`}
  }

  .buttonContainer {
    ${tw`text-center p-6 relative`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default (
    {
      primaryButtonUrl = "https://calendly.com/empowert3",
      buttonRounded = true,
    }
) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: CustomTrainingIcon,
      title: "Custom Training and/or Materials",
      description: "Our team can provide live training sessions (remote or in-person), written or recorded materials, and process documentation. These are designed to be evergreen materials designed specifically for you and your organization's needs."
    },
    {
      imageSrc: DataCleanUpIcon,
      title: "Data Clean Up",
      description: "This option is great for any organization who needs sweeping changes made to their accounting data, but don’t have the time or expertise to devote to the project. Let our team help make changes, update information, or catch up on data entry while you focus on your mission. "
    },
    {
      imageSrc: GeneralConsultingIcon,
      title: "General Consulting",
      description: "Sometimes you just need someone to help talk through a situation, evaluate a need, dig into an issue, or be slightly cheaper therapy! This option can also be purchased as a retainer, and is suggested for most of our services in case of follow up."
    },
    {
      imageSrc: MonthlyBookkeepingIcon,
      title: "Monthly Bookkeeping",
      description: "Our team can be your out-sourced data entry bookkeeping specialist. Let us take care of the upkeep while you grow your organization. This service has a base monthly cost of $250 which includes the first hour of work performed. Each additional hour is billed at the $150/hour rate."
    }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Additional <span tw="text-primary-700">Services</span></Heading>
        <Subheading tw="text-gray-900">In addition to the above packages, our team can also provide the following services priced at $150 per hour. Our exploratory call will provide you with an estimate of the total cost, but this may vary depending on the increase of scope. If any deviations occur we will be sure to inform and work with you so that we can best accomplish your needs within your budget.</Subheading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Filler text"}
                </p>
              </span>
              {/*<span className="buttonContainer">*/}
                <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl} style={{marginTop: 'auto'}}>
                  <>Schedule a Call</>
                </PrimaryButton>
              {/*</span>*/}
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
