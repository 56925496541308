import React from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import EmailIllustrationSrc from "images/empower-mobile-mail-01-min.png";
import MailChimpForm from "./MailChimpForm";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-primary-800`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-gray-700 text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm text-gray-700 md:text-base lg:text-lg font-medium leading-relaxed`


export default ({
  subheading = "Contact Us",
  textOnLeft = true,
}) => {

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>Feel free to <span tw="text-primary-700">get in touch</span><wbr/> with us.</Heading>
            <Description>If you have any questions, send them right over! Someone from our incredibly competent, educated, and intelligent staff usually get back to you by next business day.</Description>
            {/*<div className="ml-embedded" data-form="bAT5Xm"></div>*/}
            <MailChimpForm />
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
