import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage";
import SimpleSubscribeNewsletter from "./Training/ContactFormOne";
import FAQ from "components/MainFAQ";
// import Footer from "main-footer.js";

export default () => (
    <AnimationRevealPage>
        <SimpleSubscribeNewsletter />
        {/*<FAQ />*/}
        {/*<Footer />*/}
    </AnimationRevealPage>
);

