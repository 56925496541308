import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import ContactUsForm from "pages/Contact/TwoColumnWithMailerLite.js";

export default () => {
  return (
    <AnimationRevealPage>
      <ContactUsForm />
    </AnimationRevealPage>
  );
};
