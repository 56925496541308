import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Pricing1 from "pages/Pricing/ThreePlans.js";
import Pricing2 from "pages/Pricing/ThreePlansWithHalfPrimaryBackground.js";
import Pricing3 from "pages/Pricing/TwoPlansWithDurationSwitcher.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/main-footer.js";
import FAQ from "components/MainFAQ";

export default () => {
  return (
    <AnimationRevealPage>
      <Pricing1 />
        <Pricing2 />
        <Pricing3 />
      {/*<Testimonial*/}
      {/*  heading="Our Paying Customers"*/}
      {/*/>*/}
      {/*<FAQ />*/}
      <Footer/>
    </AnimationRevealPage>
  );
};
